.badge {
  line-height: 0.8125rem;
}

.badge-circle {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  padding: 0rem 0.85rem;
}
